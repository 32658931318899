










































import RefundModel from '@/models/Refund.model';
import RefundsRepository from '@/repository/modules/refunds.repository';
import { setterDate } from '@/plugins/utils';

import { Component, Vue } from 'vue-property-decorator';
@Component
export default class ListRefunds extends Vue {
  private listRefunds: RefundModel[] = [];
  page = 1;
  limit = 100;
  total_results = 0;
  loading = false;

  async mounted(): Promise<void> {
    await this.getRefunds(this.page);
  }

  get total_pages() {
    return Math.round(this.total_results / this.limit) + 1;
  }

  async getRefunds(page: number, query: object = {}): Promise<void> {
    this.loading = true;
    const { refunds, total } = await RefundsRepository.getListRefunds({ query, page, limit: this.limit });
    this.listRefunds = refunds;
    this.total_results = total ?? 0;
    this.loading = false;
  }

  changePage(page: number) {
    this.getRefunds(page);
  }
}
