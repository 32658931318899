































































































import { Component, Vue } from 'vue-property-decorator';
import PaymentModel from '@/models/Payment.model';
import { setterDate, setterAmount } from '@/plugins/utils';
import PaymnetsRepository from '@/repository/modules/payments.repository';

interface QueryImpl {
  payment_country?: string | null;
  dates?: { [s: string]: string | null };
}

@Component
export default class ListPayments extends Vue {
  private payments: PaymentModel[] = [];
  date = [];
  country = null;
  menu = false;
  page = 1;
  limit = 25;
  total_results = 0;
  loading = false;

  get dateRangeText() {
    return this.date.join(' ~ ');
  }

  get total_pages() {
    return Math.round(this.total_results / this.limit) + 1;
  }

  async mounted(): Promise<void> {
    await this.getPayments(this.page);
  }

  async getPayments(page: number, query: object = {}): Promise<void> {
    this.loading = true;
    const { payments, total } = await PaymnetsRepository.getListPayments({ query, page, limit: this.limit });
    this.payments = payments;
    this.total_results = total ?? 0;
    this.loading = false;
    console.log(payments);
  }

  changePage(page: number) {
    this.getPayments(page, this.queryBuilder());
  }

  findListPayments() {
    this.page = 1;
    this.getPayments(1, this.queryBuilder());
  }

  clearAndfind() {
    this.country = null;
    this.date = [];
    this.findListPayments();
  }

  queryBuilder() {
    const obj: QueryImpl = {};
    if (this.country != null) {
      obj.payment_country = this.country;
    }
    if (this.date[0] || this.date[1]) {
      obj.dates = {};
      if (this.date[0]) {
        obj.dates.from = this.date[0];
      }
      if (this.date[1]) {
        obj.dates.to = this.date[1];
      }
    }
    return obj;
  }

  setDate = (dater: Date) => setterDate(dater);
  setAmount = (amount: number) => setterAmount(amount);
}
