import API from '../repository';
import RefundModel, { RefundModelDTO } from '@/models/Refund.model';

const RESOURCE = '/refunds';

export default {
  async getListRefunds({ query = {}, page = 0, limit = 100 }): Promise<{ refunds: RefundModel[]; total: number }> {
    const { listRefunds, total } = await API.post(`${RESOURCE}`, {
      page,
      limit,
      query,
    });
    const refundsListModels = listRefunds.map((refundDto: RefundModelDTO) => new RefundModel(refundDto));
    return {
      refunds: refundsListModels,
      total: total,
    };
  },
};
